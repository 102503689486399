import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "canvas-panel" },
    [
      _c(
        VLayout,
        { attrs: { right: "" } },
        [
          _vm.definition
            ? _c("bpmn-vue", {
                ref: "bpmn-vue",
                style: _vm.cursorStyle,
                attrs: {
                  definition: _vm.definition,
                  monitor: _vm.monitor,
                  backend: _vm.backend,
                  dragPageMovable: _vm.dragPageMovable,
                  loaded: _vm.loaded,
                },
                on: {
                  "update:definition": function ($event) {
                    _vm.definition = $event
                  },
                  bpmnReady: _vm.bindEvents,
                  "update:loaded": function ($event) {
                    _vm.loaded = $event
                  },
                },
              })
            : _vm._e(),
          _c(
            VLayout,
            [
              _c(
                VFlex,
                [
                  _c(
                    VRow,
                    {
                      staticStyle: {
                        "margin-left": "20px",
                        "margin-top": "10px",
                      },
                    },
                    [
                      _c(
                        VTooltip,
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function ({ on }) {
                                return [
                                  _c(
                                    VBtn,
                                    _vm._g(
                                      {
                                        attrs: {
                                          text: "",
                                          small: "",
                                          right: "",
                                        },
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.undo.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                        },
                                      },
                                      on
                                    ),
                                    [
                                      _c(VIcon, { attrs: { medium: "" } }, [
                                        _vm._v("mdi-undo"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ]),
                        },
                        [_c("span", [_vm._v("Undo")])]
                      ),
                      _c(
                        VTooltip,
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function ({ on }) {
                                return [
                                  _c(
                                    VBtn,
                                    _vm._g(
                                      {
                                        attrs: {
                                          text: "",
                                          small: "",
                                          right: "",
                                        },
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.redo.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                        },
                                      },
                                      on
                                    ),
                                    [
                                      _c(VIcon, { attrs: { medium: "" } }, [
                                        _vm._v("mdi-redo"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ]),
                        },
                        [_c("span", [_vm._v("Redo")])]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            VFlex,
            { staticStyle: { justify: "end", align: "start" } },
            [
              !_vm.monitor
                ? _c(
                    VRow,
                    {
                      staticStyle: { "margin-right": "15px" },
                      attrs: { justify: "end", align: "start" },
                    },
                    [
                      _c(VTextField, {
                        staticStyle: {
                          "margin-right": "10px",
                          "margin-top": "8px",
                          "max-width": "300px",
                        },
                        attrs: { label: "Process Name" },
                        model: {
                          value: _vm.definitionName,
                          callback: function ($$v) {
                            _vm.definitionName = $$v
                          },
                          expression: "definitionName",
                        },
                      }),
                      _c(
                        "div",
                        { staticStyle: { "margin-right": "10px" } },
                        [
                          _vm._v("> \n                    "),
                          _c(VMenu, {
                            attrs: {
                              "offset-y": "",
                              "open-on-hover": "",
                              left: "",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function ({ on }) {
                                    return [
                                      _c(
                                        VBtn,
                                        _vm._g(
                                          {
                                            staticStyle: {
                                              "margin-right": "15px",
                                              "margin-top": "15px",
                                            },
                                            attrs: {
                                              text: "",
                                              color: "primary",
                                              dark: "",
                                            },
                                            on: {
                                              click: _vm.openDefinitionSettings,
                                            },
                                          },
                                          on
                                        ),
                                        [
                                          _c(VIcon, [_vm._v("settings")]),
                                          _vm._v(
                                            "\n                                Settings\n                            "
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2955785676
                            ),
                          }),
                          _c(VMenu, {
                            attrs: {
                              "offset-y": "",
                              "open-on-hover": "",
                              left: "",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function ({ on }) {
                                    return [
                                      _c(
                                        VBtn,
                                        _vm._g(
                                          {
                                            staticStyle: {
                                              "margin-right": "15px",
                                              "margin-top": "15px",
                                            },
                                            attrs: {
                                              text: "",
                                              id: "processVariables",
                                              color: "orange",
                                              dark: "",
                                            },
                                            on: {
                                              click: _vm.openProcessVariables,
                                            },
                                          },
                                          on
                                        ),
                                        [
                                          _c(VIcon, [
                                            _vm._v("sort_by_alpha"),
                                          ]),
                                          _vm._v(
                                            "\n                                Vars\n                            "
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2032676952
                            ),
                          }),
                        ],
                        1
                      ),
                      _vm.versions
                        ? _c(
                            "div",
                            { staticStyle: { "margin-right": "10px" } },
                            _vm._l(
                              _vm.versions.slice().reverse(),
                              function (version, idx) {
                                return _c(
                                  VSelect,
                                  _vm._g(
                                    {
                                      key: idx,
                                      staticStyle: {
                                        "margin-top": "10px",
                                        "margin-right": "15px",
                                        "max-width": "150px",
                                      },
                                      attrs: { value: version },
                                      on: { change: _vm.changeVersion },
                                      model: {
                                        value: _vm.selectedVersion,
                                        callback: function ($$v) {
                                          _vm.selectedVersion = $$v
                                        },
                                        expression: "selectedVersion",
                                      },
                                    },
                                    _vm.on
                                  ),
                                  [
                                    _vm.productionVersionId == version.versionId
                                      ? _c(VChip, [_vm._v("production")])
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              }
                            ),
                            1
                          )
                        : _vm._e(),
                      _vm.definition
                        ? _c(
                            "div",
                            { staticStyle: { "margin-right": "10px" } },
                            [
                              _c(VSelect, {
                                staticStyle: {
                                  "margin-top": "10px",
                                  "margin-right": "15px",
                                  "max-width": "150px",
                                },
                                attrs: {
                                  items: _vm.languageItems,
                                  "item-value": "value",
                                  "item-text": "title",
                                  label: "Language",
                                },
                                on: { change: _vm.changeLocale },
                                model: {
                                  value: _vm.definition._selectedLocale,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.definition,
                                      "_selectedLocale",
                                      $$v
                                    )
                                  },
                                  expression: "definition._selectedLocale",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        [
                          _c(VMenu, {
                            attrs: {
                              "offset-y": "",
                              "open-on-hover": "",
                              left: "",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function ({ on }) {
                                    return [
                                      _c(
                                        VBtn,
                                        _vm._g(
                                          {
                                            staticStyle: {
                                              "margin-right": "15px",
                                              "margin-top": "15px",
                                            },
                                            attrs: {
                                              color: "primary",
                                              dark: "",
                                              fab: "",
                                              small: "",
                                            },
                                            on: { click: _vm.initiateProcess },
                                          },
                                          on
                                        ),
                                        [_c(VIcon, [_vm._v("play_arrow")])],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              4251299703
                            ),
                          }),
                          _c(VMenu, {
                            attrs: {
                              "offset-y": "",
                              "open-on-hover": "",
                              left: "",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function ({ on }) {
                                    return [
                                      _c(
                                        VBtn,
                                        _vm._g(
                                          {
                                            staticStyle: {
                                              "margin-right": "15px",
                                              "margin-top": "15px",
                                            },
                                            attrs: {
                                              color: "orange",
                                              dark: "",
                                              fab: "",
                                              small: "",
                                            },
                                            on: { click: _vm.save },
                                          },
                                          on
                                        ),
                                        [
                                          _vm.id && _vm.id.indexOf("@") == -1
                                            ? _c(VIcon, [_vm._v("save")])
                                            : _c(VIcon, [_vm._v("history")]),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3462690220
                            ),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _c(
                    VRow,
                    {
                      staticStyle: { "margin-right": "15px" },
                      attrs: { justify: "end", align: "start" },
                    },
                    [
                      _c(VTextField, {
                        staticStyle: {
                          "margin-right": "10px",
                          "margin-top": "8px",
                          "max-width": "300px",
                        },
                        attrs: {
                          label: "Instance Name",
                          type: "text",
                          readonly: "",
                        },
                        model: {
                          value: _vm.instanceName,
                          callback: function ($$v) {
                            _vm.instanceName = $$v
                          },
                          expression: "instanceName",
                        },
                      }),
                      _c(
                        "div",
                        { staticStyle: { "margin-right": "10px" } },
                        [
                          _c(VMenu, {
                            attrs: {
                              "offset-y": "",
                              "open-on-hover": "",
                              left: "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "activator",
                                fn: function ({ on }) {
                                  return [
                                    _c(
                                      VBtn,
                                      _vm._g(
                                        {
                                          staticStyle: {
                                            "margin-right": "15px",
                                            "margin-top": "15px",
                                          },
                                          attrs: {
                                            text: "",
                                            id: "instanceVariables",
                                            color: "primary",
                                            dark: "",
                                          },
                                          on: {
                                            click: _vm.openInstanceVariables,
                                          },
                                        },
                                        on
                                      ),
                                      [
                                        _vm._v(
                                          "\n                                Variables\n                            "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c(VMenu, {
                            attrs: {
                              "offset-y": "",
                              "open-on-hover": "",
                              left: "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "activator",
                                fn: function ({ on }) {
                                  return [
                                    _c(
                                      VBtn,
                                      _vm._g(
                                        {
                                          staticStyle: {
                                            "margin-right": "15px",
                                            "margin-top": "15px",
                                          },
                                          attrs: {
                                            text: "",
                                            id: "userPicker",
                                            color: "orange",
                                            dark: "",
                                          },
                                          on: { click: _vm.openUserPicker },
                                        },
                                        on
                                      ),
                                      [
                                        _vm._v(
                                          "\n                                Role Mappings\n                            "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                      _vm.versions
                        ? _c(
                            "div",
                            { staticStyle: { "margin-right": "10px" } },
                            _vm._l(
                              _vm.versions.slice().reverse(),
                              function (version, idx) {
                                return _c(
                                  VSelect,
                                  _vm._g(
                                    {
                                      key: idx,
                                      staticStyle: {
                                        "margin-top": "10px",
                                        "margin-right": "15px",
                                        "max-width": "150px",
                                      },
                                      attrs: { value: version },
                                      on: { change: _vm.changeVersion },
                                      model: {
                                        value: _vm.selectedVersion,
                                        callback: function ($$v) {
                                          _vm.selectedVersion = $$v
                                        },
                                        expression: "selectedVersion",
                                      },
                                    },
                                    _vm.on
                                  ),
                                  [
                                    _vm.productionVersionId == version.versionId
                                      ? _c(VChip, [_vm._v("production")])
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              }
                            ),
                            1
                          )
                        : _vm._e(),
                      _vm.definition
                        ? _c("bpmn-instance-variables", {
                            ref: "instanceVariables",
                            attrs: {
                              id: _vm.instanceId,
                              definition: _vm.definition,
                            },
                          })
                        : _vm._e(),
                      _vm.definition
                        ? _c("user-picker", {
                            ref: "userPicker",
                            staticStyle: { "min-width": "70%" },
                            attrs: {
                              id: _vm.instanceId,
                              roles: _vm.definition.roles,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
            ],
            1
          ),
          !_vm.monitor
            ? _c(
                VCard,
                { staticClass: "tools", staticStyle: { top: "100px" } },
                [
                  _c(
                    VTooltip,
                    {
                      attrs: { right: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on }) {
                              return [
                                _c(
                                  "span",
                                  _vm._g(
                                    {
                                      staticClass: "bpmn-icon-hand-tool hands",
                                      style: _vm.handsStyle,
                                      attrs: { _width: "30", _height: "30" },
                                      on: { click: _vm.changeMultiple },
                                    },
                                    on
                                  )
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        960570545
                      ),
                    },
                    [_c("span", [_vm._v("Drag On/Off")])]
                  ),
                  _vm._l(_vm.dragItems, function (item, idx) {
                    return _c(
                      VTooltip,
                      {
                        key: idx,
                        attrs: { right: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function ({ on }) {
                                return [
                                  _c(
                                    "span",
                                    _vm._g(
                                      {
                                        staticClass: "icons draggable",
                                        class: item.icon,
                                        attrs: {
                                          align: "center",
                                          _component: item.component,
                                          _width: item.width,
                                          _height: item.height,
                                        },
                                      },
                                      on
                                    )
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      [_c("span", [_vm._v(_vm._s(item.label))])]
                    )
                  }),
                ],
                2
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._m(0),
      _c("canvas", { ref: "image-gen", staticStyle: { display: "none" } }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", { staticClass: "custom-menu" }, [
      _c("li", { attrs: { "data-action": "backToHere" } }, [
        _vm._v("Back To Here"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }